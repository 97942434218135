// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
@font-face {
  font-family: Montserrat;
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf"); //change url accordingly
}

$font-family-icomoon: 'icomoon' !important;
$icomoon-font-path: '../assets/fonts' !default;

@font-face {
  font-family: 'icomoon';
  src: url('#{$icomoon-font-path}/icomoon.ttf?rydnse') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?rydnse') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?rydnse#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


// Comfortaa
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/font-family/Comfortaa-Light.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/font-family/Comfortaa-Regular.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/font-family/Comfortaa-Bold.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

* {
  font-family: 'Comfortaa', sans-serif;
  font-size: 100%;
}

$purple-darker: #313551;

:root {
  @import "./sweetalert.scss";
  @import "./colors-variables.scss";
  @import "./modules/form-list.scss";
  @import "./modules/select.scss";
  @import "./modules/action-sheet.scss";
  @import "./modules/container-grafico.scss";
  @import "./modules/slides-time-chart.scss";
  @import "./modules/container-dismiss.scss";
  @import "./modules/modal-transparent.scss";
  
  ion-content {
    max-width: 720px;
    align-self: center;
    padding: 0 constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); //iOS 11.2
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
  @media (max-height: 570px) {
    .hidden {
      display: none;
    }
  }

  ion-button {
    text-transform: none;
   }

  .native-input.sc-ion-input-md:-webkit-autofill {
    height: 0px;
    box-shadow: 0 0 0 10px var(--main-color) inset;
    -webkit-text-fill-color: #ffffff !important;
    //webkit-background-clip: text;
  }

  // --ion-font-family: Montserrat;
  --ion-font-family: Comfortaa;
  --main-color: #313552;
  --main-color-rgb: 49, 53, 82;

  /** primary **/
  --ion-color-primary: #4a90e2;
  --ion-color-primary-rgb: 74, 144, 226;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #417fc7;
  --ion-color-primary-tint: #5c9be5;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** orange **/
  --ion-color-orange: #ff8000;
  --ion-color-orange-rgb: 255, 128, 0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #e07100;
  --ion-color-orange-tint: #ff8d1a;

  --ion-color-dimgrey: #696969;
  --ion-color-dimgrey-rgb: 105,105,105;
  --ion-color-dimgrey-contrast: #ffffff;
  --ion-color-dimgrey-contrast-rgb: 255, 255, 255;
  --ion-color-dimgrey-shade: #000000;
  --ion-color-dimgrey-tint: #2a2a2a;

  /** lightblue **/
  --ion-color-lightblue: #ffffff;
  --ion-color-lightblue-rgb: 255, 255, 255;
  --ion-color-lightblue-contrast: #4a90e2;
  --ion-color-lightblue-contrast-rgb: 74, 144, 226;
  --ion-color-lightblue-shade: #000000;
  --ion-color-lightblue-tint: #000000;

  // Styles for SweetAlert message
  .sweet-alert-text {
    font-size: 14px;
    text-align: left;

    .alert-highlight {
      color: #d33;
    }

    .alert-important {
      color: #ff8000;
    }
  }

  .sweet-alert-text-center {
    font-size: 14px;
    text-align: center;

    .alert-highlight {
      color: #d33;
    }

    .alert-important {
      color: #ff8000;
    }
  }

  .items {
    .item {
      background-color: #4b4d6b;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      &.no-left-radius {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      &.no-right-radius {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      .info {
        border-left: 15px solid red;
        flex: 1;
        color: #fff;
        text-align: left;
        padding: 8px;
        p {
          margin: 0;
          font-size: 65%;
          color: #4990e2;
        }
        h2 {
          font-size: 80%;
          font-weight: bold;
        }

        h3 {
          font-size: 70%;
          color: #ccc;
        }
      }

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          background-color: transparent;
          font-size: 100%;
          box-shadow: none;
          text-align: right;
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  .lista-ciclos {
    color: #1d202f !important;
    text-align: left;

    ion-icon {
      color: red;
      font-size: 20px !important;
      min-width: 20px;
    }

    &.is-active {
      color: #ff6600 !important;
    }

    line-height: 1.1;
  }
  .lista-ciclos-add {
    color: #5a90dc !important;
    text-align: right;
  }
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body[color-theme="dark"] {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body[color-theme="dark"] {
  --ion-background-color: var(--main-color);;
  --ion-background-color-rgb: 49, 53, 82;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: var(--main-color);
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: var(--main-color);
  --ion-card-background: var(--main-color);;
  --ion-toolbar-background: var(--main-color);

}

.ios ion-modal[color-theme="dark"] {
  --ion-background-color: var(--main-color);
  --ion-toolbar-background: var(--main-color);
  --ion-toolbar-border-color: var(--main-color);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body[color-theme="dark"] {
  --ion-background-color: var(--main-color);
  --ion-background-color-rgb: var(--main-color-rgb);

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #6f7286;

  --ion-color-step-50: #3b3f5b;
  --ion-color-step-100: #464963;
  --ion-color-step-150: #50536c;
  --ion-color-step-200: #5a5d75;
  --ion-color-step-250: #65687d;
  --ion-color-step-300: #6f7286;
  --ion-color-step-350: #797c8f;
  --ion-color-step-400: #838697;
  --ion-color-step-450: #8e90a0;
  --ion-color-step-500: #989aa9;
  --ion-color-step-550: #a2a4b1;
  --ion-color-step-600: #adaeba;
  --ion-color-step-650: #b7b8c2;
  --ion-color-step-700: #c1c2cb;
  --ion-color-step-750: #cccdd4;
  --ion-color-step-800: #d6d7dc;
  --ion-color-step-850: #e0e1e5;
  --ion-color-step-900: #eaebee;
  --ion-color-step-950: #f5f5f6;

  --ion-item-background: var(--ion-color-step-50);

  --ion-toolbar-background: var(--ion-color-step-50);

  --ion-tab-bar-background: var(--ion-color-step-50);

  --ion-card-background: var(--ion-color-step-50);
}
//}
