$bg: #fff;
$font-color: rgba(0,0,0,.4);

.custom-select-item {
  color: $font-color;
  background-color: $bg;
  font-weight: bold;
  letter-spacing: 0.9px;
  height: 20px;
  min-height: 46px;
  padding-left: 0;
  border-radius: 8px;
  font-size: 1rem;
  border: 0.55px solid #6D7D93;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: {
    left: 12px;
    right: 12px;
  }

  &.dark {
    background: #4A4D6E;
    border-color: #4A4D6E;
    color: rgba(255, 255, 255, 0.8);
  }

  &.textarea {
    margin: 20px 5% 32px;
    border-color: rgba(#6D7D93, 0.4);

    .item-inner{
      padding: 0 8px 8px;
    }
  }

  &.last-item {
    margin-bottom: 32px;
  }

  ion-select {
    width: 98%;
    max-width: 100%;
  }

  ion-input {
    .text-input {
      margin-left: 16px;
      &::placeholder {
        color: #fff !important;
      }
    }
  }

  ion-input.danger-placeholder {
    .text-input {
      margin-left: 16px;
      &::placeholder {
        color: #d33 !important;
      }
    }
  }
}