ion-list {
  &.accordion-list {
    background: transparent !important;
    .accordion-item {
      background-color: transparent;
      text-align: center;


      .accordion-header {
        display: flex;
        align-items: center;

        ion-button {
          background-color: transparent;
          box-shadow: none;
          position: absolute;
          right: 10px;
          padding: 0;
        }


        .title-header {
          flex: 1;
          color: #fff !important;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          padding: {
            left: 30px;
            right: 30px;
          }


          p {
            width: 100%;
          }


          &::after, &::before {
            content: '';
            height: 1px;
            width: 50%;
            background-color: rgba($color: #fff, $alpha: .4);
          }
        }
      }


      .accordion-content {
        transition: all linear .2s;
        ion-list, ion-item {
          --background: transparent !important;
          background: transparent !important;
        }

        &.hide {
          height: 0px;
          display: none;
        }
      }
    }
  }
} 