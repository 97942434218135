/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './theme/colors.scss';
@import './theme/modules/form-list.scss';
@import './theme/modules/accordion-list.scss';
@import './theme/modules/modal-history-filter.scss';
@import './theme/modules/modal-fullscreen.scss';
@import './theme/modules/modal-sd.scss';
@import './theme/modules/modal-sd-small.scss';
@import './theme/modules/box-ion-item.scss';

ion-modal {
  .sc-ion-modal-md {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.75);
  }
}

.alert-wrapper {
  min-width: 300px !important;
}

.sc-ion-label-md-h {
  white-space: normal !important;
}

ion-button {
  white-space: initial !important;
}

.text-center {
  text-align: center;
}

ion-item {
  &.transparent {
    --background: transparent;
  }
}

.finished-block {
  text-decoration: line-through;
  color: rgba($color: #fff, $alpha: 0.4);
}

.select-item {
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 90%;
  height: 20px;
  min-height: 40px;
  margin: 0 5% 12px;
  padding-left: 0;
  border-radius: 8px;
  border: 0.55px solid #6d7d93;

  &.textarea {
    margin: 20px 5% 32px;
    border-color: rgba(#6d7d93, 0.4);

    .item-inner {
      padding: 0 8px 8px;
    }
  }

  &.last-item {
    margin-bottom: 32px;
  }

  ion-select {
    width: 98%;
    max-width: 100%;
  }

  ion-input {
    --padding-top: 5px;
    .text-input {
      margin-left: 16px;
      &::placeholder {
        color: #fff !important;
      }
    }
  }

  ion-input.danger-placeholder {
    .text-input {
      margin-left: 16px;
      &::placeholder {
        color: #d33 !important;
      }
    }
  }
}

.logged-as {
  color: #fff;
  font-size: 0.8em;
  margin-bottom: 0.5em;
  text-align: left;
}

.action-sheet-cancel {
  span {
    color: #dd3333 !important;
  }

  .action-sheet-button-inner {
    color: #dd3333 !important;
  }
}

.swiper-pagination-bullet {
  opacity: 1;
}

.action-sheet-button.sc-ion-action-sheet-md,
.action-sheet-button.sc-ion-action-sheet-ios {
  line-height: 19px;
  ion-icon {
    margin-right: 20px;
  }
}

.action-sheet-button.sc-ion-action-sheet-md:nth-child(2),
.action-sheet-button.sc-ion-action-sheet-ios:nth-child(2) {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.crown {
  display: flex;

  img {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: -2px;
  }

  ion-icon {
    position: relative;
  }
}


.action-sheet-crown{
  --button-background: url('assets/imgs/plans-compare/coroa.png') 95% 0% no-repeat;
}