.slides-time-chart {
  ion-slides {
    overflow-y: hidden;
    .swiper-pagination,
    .swiper-pagination-clickable,
    .swiper-pagination-bullets {
      top: 0 ;
      width: 100%;
      max-height: 10px;
  
    }

    &:not(.has-charts) {
      .swiper-pagination,
      .swiper-pagination-clickable,
      .swiper-pagination-bullets {
        display: none !important;
      }
    }

    ion-slide {
      &.has-chart {
        overflow-y: hidden;
        background-color: #ffffff;
      }
      .slide-zoom {
        display: block;
        height: 100%;
        width: 100%;

        .chart {
          overflow: hidden;
          height: 100%;
          transform: translate(0, 10px);

          .img-detail-click {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            img {
              width: 14px;
              height: 14px;
              margin-right: 7px;
            }
          }

          .highcharts-legend {
            & > g:nth-child(3) {
              transform: translate(-55px, 10px);
            }
          }
        }

        .empty-grafico {
          color: #ffffff;
          font-size: 1.2em;
        }
      }
    }
  }
}
