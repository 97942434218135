.container-grafico {
  border: 1px solid transparent !important;
  height: 100vw;
  width: 100% !important;
  // height: calc(100vw - 16px);

  &.hidden-chart {
    height: 0;
  }

  .highcharts-tooltip,
  .highcharts-credits,
  .highcharts-title {
    display: none;
    fill: red !important;
  }

  .highcharts-title {
    fill: #fff !important;
  }

  .highcharts-background,
  .highcharts-plot-border,
  .highcharts-plot-background {
    fill: transparent !important;
  }
}

