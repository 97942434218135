.form-item-list {
  .item {
    .custom-select-item {
      border-radius: 5px;
    }

    .item-label {
      color: #fff;
      margin: 0;
      font-size: 1rem;

      &.dark {
        color: #999;
      }
    }

    .item-field {
      background-color: #4a4d6e;
      border-radius: 5px;
      height: 30px;
      width: 100%;
    }
  }
}
