.ion-color-orange {
  --ion-color-base: var(--ion-color-orange) !important;
  --ion-color-base-rgb: var(--ion-color-orange-rgb) !important;
  --ion-color-contrast: var(--ion-color-orange-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-orange-shade) !important;
  --ion-color-tint: var(--ion-color-orange-tint) !important;
}

.ion-color-dimgrey {
  --ion-color-base: var(--ion-color-dimgrey) !important;
  --ion-color-base-rgb: var(--ion-color-dimgrey-rgb) !important;
  --ion-color-contrast: var(--ion-color-dimgrey-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-dimgrey-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-dimgrey-shade) !important;
  --ion-color-tint: var(--ion-color-dimgrey-tint) !important;
}


.ion-color-lightblue {
  --ion-color-base: var(--ion-color-lightblue) !important;
  --ion-color-base-rgb: var(--ion-color-lightblue-rgb) !important;
  --ion-color-contrast: var(--ion-color-lightblue-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast) !important;
  --ion-color-shade: var(--ion-color-lightblue-shade) !important;
  --ion-color-tint: var(--ion-color-lightblue-tint) !important;
}
